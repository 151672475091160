export default function Legal() {
    return (
        <div className="PrivacyDiv">
            <h2>Terminos de servicio</h2>
            <p>Actualizado el 22 de mayo de 2024</p>
            <p>The Random BOT ("nosotros", "nuestro" o "nos") se compromete a proteger su privacidad. Esta Politica de privacidad explica cómo The Random BOT recopila, usa y divulga su información personal. Esta Política de privacidad se aplica a <a href=" https://therandombot.xyz">nuestro sitio web</a>, y sus subdominios asociados (colectivamente, nuestro "Servicio") junto con nuestra aplicación, The Random BOT. Al acceder o utilizar nuestro Servicio, usted indica que ha leido, comprendido y aceptado nuestra recopilación, almacenamiento, uso y divulgación de su información personal como se describe en esta Política de privacidad y en nuestros Términos de servicio.</p>
            <p>Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomiendo y enfatizo revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.</p>
            <h3>Anuncios</h3>
            <p>Usted acepta que el bot, "The Random BOT", está autorizado a enviar anuncios y mensajes que el propietario del bot considere importantes para el conocimiento de sus usuarios. Dichos mensajes podrán ser enviados en los canales cuyo nombre incluya la palabra "general" o en el canal de mensajes del sistema especificado en la configuración del servidor de Discord.</p>
            <h3>Contenido del bot</h3>
            <p>El propietario de "The Random BOT" no se hace responsable por cualquier tipo de ofensa, daño o perjuicio que pueda surgir a partir de los mensajes aleatorios enviados por el bot a los canales especificados. Al utilizar "The Random BOT", usted acepta y entiende que dichos mensajes son generados con fines de entretenimiento y comedia, y no deben ser tomados como afirmaciones serias, recomendaciones, o declaraciones de hechos. Los mensajes aleatorios generados por "The Random BOT" están destinados exclusivamente a proporcionar entretenimiento y diversión a los usuarios. Estos mensajes pueden incluir contenido humorístico, chistes, o comentarios que no reflejan las opiniones del propietario del bot, ni están destinados a ofender a ninguna persona o grupo.</p>
            <p>Al utilizar "The Random BOT" y permitir que envíe mensajes aleatorios en sus canales, usted acepta asumir el riesgo de cualquier posible ofensa que dichos mensajes puedan causar. Usted entiende y acepta que el propietario del bot no puede prever todas las posibles interpretaciones de los mensajes y, por lo tanto, no puede ser considerado responsable por cualquier consecuencia derivada de la interpretación del contenido por parte de los usuarios. Si considera que algún mensaje es inapropiado o ofensivo, usted tiene la facultad de eliminar dicho mensaje y/o configurar las restricciones necesarias para el uso del bot en su servidor.</p>
            <h3>Afiliaciones</h3>
            <p>Esta aplicación no está afiliada, respaldada, patrocinada ni fabricada por Discord Inc. Cualquier conexión directa o indirecta con Discord Inc. o con cualquiera de sus marcas registradas es completamente fortuita y no intencional. Todas las marcas registradas, logotipos y nombres comerciales son propiedad de sus respectivos dueños. El desarrollador de "The Random BOT" no reclama ningún derecho sobre las marcas registradas o nombres comerciales de Discord Inc. y respeta plenamente los derechos de propiedad intelectual de todas las partes. Todas las marcas registradas, logotipos y nombres comerciales son propiedad de sus respectivos dueños. El desarrollador de "The Random BOT" no reclama ningún derecho sobre las marcas registradas o nombres comerciales de Discord Inc. y respeta plenamente los derechos de propiedad intelectual de todas las partes.</p>
            <h3>Responsabilidad</h3>
            <p>El propietario de esta aplicación no se hace responsable por cualquier incumplimiento de los <a href="https://discord.com/terms">Términos de servicio</a>, la <a href="https://discord.com/privacy">Política de privacidad</a> y/o las <a href="https://discord.com/guidelines">Directivas de la comunidad</a> de Discord Inc. por parte de los usuarios que utilicen esta aplicación. Los usuarios son plenamente responsables de asegurarse de que su uso de "The Random BOT" cumple con todas las políticas y directrices establecidas por Discord Inc. El propietario de "The Random BOT" no asume ninguna responsabilidad por acciones, omisiones o comportamientos de los usuarios que resulten en violaciones de las reglas y regulaciones de Discord Inc. En caso de cualquier violación de los términos, políticas o directrices de Discord Inc. por parte de los usuarios, estos serán los únicos responsables de sus acciones y deberán asumir las consecuencias legales y/o reglamentarias que correspondan.</p>
            <h2>Política de Privacidad</h2>
            <h3>Información Recopilada</h3>
            <p>"The Random BOT" recolecta y utiliza datos proporcionados por la API de Discord y por los usuarios con el único propósito de ejecutar los comandos y funciones del bot. Toda la información recopilada es utilizada exclusivamente para mejorar la experiencia del usuario y no será compartida con terceros sin el consentimiento expreso de los usuarios, salvo cuando sea requerido por ley. Como único desarollador de la aplicación me comprometo a mantener la información proporcionada a través de "The Random BOT" segura mediante el uso de una base de datos protegida cuyo acceso está totalmente restringido.</p>
            <p>En el caso improbable de que ocurra un acceso no autorizado a la información, se notificará a los usuarios afectados de manera inmediata. Se proporcionarán detalles sobre el incidente y las medidas que se están tomando para resolverlo y prevenir futuros accesos no autorizados.</p>
        </div>  
    )
}